import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import moment from 'moment';
import { ProgressFraction, ProgressDots } from '@tribus/progress';
import Autocomplete from 'vuejs-auto-complete';
import Vue from 'vue';
// Pages
import Player from '@vimeo/player';
import { ToggleButton } from 'vue-js-toggle-button';
import { CardIcon } from '@tribus/common-icons';
import Search from './pages/search';
import VerifyUrn from './pages/verify-urn';
import Qualifications from './pages/qualifications';
import VideoManage from './pages/video-manage';
import VideoManagement from './pages/video-management';
import CardCheckerManagement from './pages/card-checker-management';
import CsvExport from './pages/csv-export';
import uBrowserBanner from './components/ui/BrowserBanner.vue';
// import uErrorBanner from './components/ui/ErrorBanner.vue';
import uSurveyBanner from './components/ui/SurveyBanner';
import cookieMixin from './mixins/Cookie';
import errorsMixin from './mixins/Errors';
import WebCamCapture from './components/fields/WebCamCapture';
import CitbSvg from './components/ui/CitbSvg';
import Flags from './components/admin/Flags.vue';
import QuestionSvg from './components/ui/QuestionSvg';

import InputSwitch from "./components/InputSwitch.vue";
import InputWysiwyg from "./components/InputWysiwyg.vue";
// window.jQuery = window.$ = $;
// require('bootstrap/js/dist/util');
// require('bootstrap/js/dist/modal');
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ConditionalSection from "./components/ConditionalSection.vue";
import MsiteUserRow from "./components/MsiteUserRow.vue";
import * as Sentry from '@sentry/vue';

import LcoSelector from './components/admin/LcoSelector.vue';
import LinkList from './components/tribus/link-list/Link-List.vue';
import FormInput from './components/tribus/form-input/FormInput.vue';
import VideoPlayerLoader from './components/tribus/video-player-loader/VideoPlayerLoader.vue';
import ProjectSelector from './components/admin/ProjectSelector.vue';
import VerifyUser from './components/admin/VerifyUser.vue';
import InvalidEnrolment from './components/admin/InvalidEnrolment.vue';
import VerifyUserTabs from "./components/admin/VerifyUserTabs.vue";
import CardSelector from "./components/registration/CardSelector.vue";
import GoSmartCardSelectorField from "./components/registration/GoSmartCardSelectorField.vue";
import GoSmartCardSelector from "./components/registration/GoSmartCardSelector.vue";
import Urn from "./components/fields/Urn.vue";
import Checkbox from "./components/fields/Checkbox.vue";
import CheckboxContainer from "./components/fields/CheckboxContainer.vue";
import Lightbox from "./components/ui/Lightbox.vue";
import Clients from "./components/passport/Clients.vue";
import AuthorizedClients from "./components/passport/AuthorizedClients.vue";
import PersonalAccessTokens from "./components/passport/PersonalAccessTokens.vue";
import LcoCardsList from "./components/admin/LcoCardsList.vue";
import DownloadCsv from "./components/admin/DownloadCsv.vue";
import ProjectList from "./components/admin/ProjectList.vue";
import FormToggle from "./directives/FormToggle";
import RemoveUpload from "./directives/RemoveUpload";
import Upload from "./directives/Upload";
import Validate from "./directives/Validate";

const jQuery = $;

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = Vue;

// JS inits
jQuery(() => {
  // Bootstrap Tooltips
  jQuery('[data-toggle="tooltip"]').tooltip();

  // Custom File Input
  jQuery('.custom-file-input').on('change', function () {
    const names = [];
    for (let i = 0; i < $(this).get(0).files.length; ++i) {
      names.push($(this).get(0).files[i].name);
    }
    $('.custom-file-input__selected').val(names);
  });

  // PDF Report - Centering (needs a better solution with a conditional appleid to the markup...)
  const reportcontainer = jQuery('.urn-profile__pdf-report');
  if (reportcontainer) {
    reportcontainer.parentsUntil('dashboard-main').addClass('urn-profile__pdf-report-container');
  }
});


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

export const eventBus = new Vue();

Vue.directive('form-toggle', FormToggle);
Vue.directive('remove-upload', RemoveUpload);
Vue.directive('upload', Upload);
Vue.directive('validate', Validate);
// Vue.directive('video-lockout', require('./directives/VideoLockout.js').default);

Vue.component(
  'u-admin-lco-selector',
  LcoSelector,
);
Vue.component(
  'link-list',
  LinkList
);
Vue.component(
  'form-input',
  FormInput
);
Vue.component(
  'video-player-loader',
  VideoPlayerLoader,
);
Vue.component(
  'u-admin-project-selector',
  ProjectSelector,
);
Vue.component(
  'u-admin-verify-user',
  VerifyUser,
);
Vue.component(
  'u-admin-invalid-enrolment',
  InvalidEnrolment,
);
Vue.component(
  'u-admin-verify-user-tabs',
  VerifyUserTabs,
);
Vue.component(
  'u-card-selector',
  CardSelector,
);
Vue.component(
  'u-gosmart-card-selector-field',
  GoSmartCardSelectorField,
);
Vue.component(
  'u-gosmart-card-selector',
  GoSmartCardSelector,
);
Vue.component('u-field-urn', Urn);
Vue.component('u-checkbox', Checkbox);
Vue.component(
  'u-checkbox-container',
  CheckboxContainer
);
Vue.component('u-lightbox', Lightbox);
Vue.component('passport-clients', Clients);
Vue.component(
  'passport-authorized-clients',
  AuthorizedClients
);
Vue.component(
  'passport-personal-access-tokens',
  PersonalAccessTokens,
);

Vue.component('WebCamCapture', WebCamCapture);
Vue.component('ToggleButton', ToggleButton);
Vue.component('CitbSvg', CitbSvg);
Vue.component('flags', Flags);
Vue.component('card-icon', CardIcon);
Vue.component('QuestionSvg', QuestionSvg);
Vue.component('lco-cards-list', LcoCardsList);
Vue.component('download-csv', DownloadCsv);
Vue.component('project-list', ProjectList);

Vue.component('autocomplete', Autocomplete);


Vue.component('InputSwitch', InputSwitch);
Vue.component('MsiteUserRow', MsiteUserRow);
Vue.component('ConditionalSection', ConditionalSection);
Vue.component('InputWysiwyg', InputWysiwyg);


Vue.config.ignoredElements = ['icon', 'progress-dots', 'progress-fraction'];

// Polyfills
svg4everybody();

require('./shims/form-attribute');
require('./shims/polyfil');

const app = new Vue({
  el: '#app',
  mixins: [cookieMixin, errorsMixin],
  data: {
    cardBack: '',
    cardFront: '',
    cardNumber: '',
    cardScheme: '',
    cardSchemeId: '',
    cardSchemeFull: '',
    cardSchemeSrc: '',
    cardType: '',
    card_type: $('select[name="card_type"]').val(),
    changeToCscs: false,
    citbCardType: '',
    citbPhoto: '',
    citbUpdated: false,
    date_of_birth_day: '',
    date_of_birth_month: '',
    date_of_birth_year: '',
    disability_act: $('input[name="disability_act"]:checked').val(),
    employer: $('select[name="employer"]').val(),
    partner_relationship: $('select[name="partner_relationship"]').val(),
    company_employed: $('input[name="company_employed"]').val(),
    errors: {},
    expiryDate_day: '',
    expiryDate_month: '',
    expiryDate_year: '',
    expiryDate: '',
    gender: $('select[name="gender"]').val(),
    images: [
      {
        src: '/assets/static/play_btn.png',
        title: 'Video',
      },
    ],
    lastName: $('[name="last_name"]').val(),
    lco: $('select[name="lco"]').val(),
    mode_of_travel: $('select[name="mode_of_travel"]').val(),
    originalCardNumber: '',
    otherCardType: $('select[name="other_card_type"]').val(),
    passCert: '',
    personalCardType: null,
    profession: $('[name="profession"]').val(),
    religion: $('select[name="religion"]').val(),
    showSide: 'front',
    company_not_listed: $('input[name="company_employed"]').val() ? $('input[name="company_employed"]').val().length > 0 : false,
    supply_chain_partner_id: $('input[name="supply_chain_partner_id"]').val(),
    supply_chain_partner: $('input[name="supply_chain_partner"]').val(),
    urn: '',
    company_employed_email: $('input[name="company_employed_email"]').val(),
    company_phone_number: $('input[name="company_employed_telephone"]').val(),
    company_address_line_one: $('input[name="company_employed_address"]').val(),
    company_postcode: $('input[name="company_employed_postcode"]').val(),
    msg: null,
    hasUploadedPhoto: false,
    icon: '',
    cardChecker_active: false,
  },
  created() {
    eventBus.$on('idphoto-change', (data) => {
      this.hasUploadedPhoto = data.picture;
    });
    Array.prototype.forEach.call(
      document.querySelectorAll('#app .has-danger'),
      (e) => {
        e.classList.remove('has-danger');
      },
    );

    this.date_of_birth_day = this.getPageElement(
      '.select-date_of_birth_day',
    );
    this.date_of_birth_month = this.getPageElement(
      '.select-date_of_birth_month',
    );
    this.date_of_birth_year = this.getPageElement(
      '.select-date_of_birth_year',
    );

    if (document.querySelector('.hidden-card-type')) {
      this.personalCardType = this.getPageElement('.hidden-card-type');
      this.lastName = this.getPageElement('.text-last_name');
      this.cardScheme = this.getPageElement('.text-card-scheme');
      this.cardSchemeFull = this.getPageElement('.text-card-scheme-full');
      this.cardSchemeId = this.getPageElement('.text-card_type__id') ? this.getPageElement('.text-card_type__id') : this.getPageElement('.select-card_type');
      this.citbCardType = this.getPageElement('.select-card_type');
      this.otherCardType = this.getPageElement('.select-other_card_type');
      this.cardNumber = this.getPageElement('.text-card_number');
      this.originalCardNumber = this.getPageElement('.text-card_number');
      this.expiryDate = this.getPageElement(
        '.day-month-year-expiry_date',
      );
      this.expiryDate_day = this.getPageElement(
        '.hidden-expiry_date_day',
      );
      this.expiryDate_month = this.getPageElement(
        '.hidden-expiry_date_month',
      );
      this.expiryDate_year = this.getPageElement(
        '.hidden-expiry_date_year',
      );
      this.urn = this.getPageElement('.hidden-urn');
      this.cardFront = document.getElementById('image-upload-card_front') !== null
        ? document.getElementById('image-upload-card_front').src
        : '';
      this.cardBack = document.getElementById('image-upload-card_back') !== null
        ? document.getElementById('image-upload-card_back').src
        : '';
      this.idPhoto = document.getElementById('image-upload-card_back') !== null
        ? document.getElementById('image-upload-card_back').src
        : '';
      this.passCert = document.getElementById('image-upload-pass_certificate')
                !== null
        ? document.getElementById('image-upload-pass_certificate')
          .src
        : '';
      this.citbPhoto = this.getPageElement('citb-cache-photo-hidden');
    }
    this.icon = document.getElementById('image-upload-icon') !== null
      ? document.getElementById('image-upload-icon').src
      : '';
  },
  mounted() {
    $('.my-gallery a').on('click', () => {
      $('.sl-wrapper .sl-image img').remove();
    });

    this.checkAge();
    this.$watch(
      vm => [
        vm.date_of_birth_day,
        vm.date_of_birth_month,
        vm.date_of_birth_year,
      ].join(),
      (val) => {
        this.checkAge();
      },
    );

    this.$nextTick(() => {
      const errorBox = document.querySelector('.field__feedback.error');
      if (errorBox !== null) {
        setTimeout(() => {
          const boundingBox = errorBox.getBoundingClientRect();
          window.scrollTo({
            top: boundingBox.top - 100,
            behavior: 'smooth',
          });
        }, 500, errorBox);
      }
    });
  },
  methods: {
    updateCompanyEmployed(event) {
      if (event.target.checked) {
        this.supply_chain_partner = null;
        this.supply_chain_partner_id = null;
        this.$refs.company_employed_autocomplete.clear();
      } else {
        this.company_employed = null;
        // This is not great but avoids breaking existing functionality
        $('input[name="company_employed"]').val('');
      }
    },
    companySelected(event) {
      const result = event.selectedObject;
      this.company_employed_email = result.email;
      this.supply_chain_partner_id = result.id;
      this.supply_chain_partner = result.name;
      this.company_phone_number = result.phone_number;
      this.company_address_line_one = result.address_line_one;
      this.company_postcode = result.postcode;
    },
    checkAge() {
      const {
        date_of_birth_day,
        date_of_birth_month,
        date_of_birth_year,
      } = this;
      if (
        date_of_birth_day !== ''
                && date_of_birth_month !== ''
                && date_of_birth_year !== ''
      ) {
        const today = new Date();
        const birthDate = new Date(
                    `${date_of_birth_year}/${date_of_birth_month}/${date_of_birth_day}`,
        );
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (
          m < 0
                    || (m === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        const $professionSelect = document.querySelector('.operative-profession');
        if ($professionSelect && age < 16) {
          this.profession = 'Student/Apprentice';
          $professionSelect.classList.add('cursor-disable');
        } else {
          $professionSelect.classList.remove('cursor-disable');
        }
        this.setErrors('registration', 'profession', null);
      }
    },
    getPageElement(element) {
      return document.querySelector(element) !== null
        ? document.querySelector(element).value
        : '';
    },
    sendWelcomeEmail(e) {
      axios.get(e.target.dataset.route).then((response) => {
        if (response.data.result == 'true') {
          alert('email sent!');
        } else {
          alert('email failed');
        }
      });
    },
    getCitbCard() {
      const submitFormBtn = document.getElementsByName('save_changes');
      submitFormBtn[0].classList.add('hidden');
      axios
        .get(
                    `/citb-card/search?scheme=${this.cardScheme}&card_number=${this.cardNumber}&last_name=${
                        this.lastName
                    }`,
        )
        .then((response) => {
          this.citbPhoto = response.data.activeCard.faceImage;
          this.personalCardType = 'gosmart';
          const expiresAtDate = moment(response.data.activeCard.expiryDate);
          this.expiryDate = expiresAtDate.format('Do of MMMM YYYY');
          this.expiryDate_day = expiresAtDate.format('D');
          this.expiryDate_month = expiresAtDate.format('M');
          this.expiryDate_year = expiresAtDate.format('YYYY');
          this.cardSchemeFull = `${this.cardSchemeFull}: ${response.data.activeCard.type}`;
          this.cardSchemeId = response.data.activeCard.id;
          this.citbUpdated = true;
          if (this.citbPhoto.indexOf('missing') !== -1) {
            this.citbPhoto = this.citbPhoto.replace(
              'missing',
              this.urn,
            );
          }
          this.cardScheme = response.data.foundCardChecker.abbreviation;
          this.cardSchemeFull = `${response.data.foundCardChecker.name}: ${response.data.activeCard.type}`;
          this.cardSchemeSrc = response.data.foundCardChecker.icon_src;
          submitFormBtn[0].classList.remove('hidden');
        })
        .catch((error) => {
          // handle error
          const errorSpans = document.querySelectorAll(
            '.text-last_name-error',
          );

          for (let i = 0; i < errorSpans.length; ++i) {
            errorSpans[i].innerHTML = error.response.data.message;
          }
        });
    },
    submitQualifications(event) {
      if (this.personalCardType === 'noncscs' || this.personalCardType === 'temp') {
        if (
          ((this.cardFront.indexOf('card-front-web') !== -1
                        || this.cardBack.indexOf('card-back-web') !== -1)
                        && (document.getElementsByName('card_front')[0].files
                          .length === 0
                            || document.getElementsByName('card_back')[0].files
                              .length == 0))
                    || (document.querySelector('input[name="photo_id"]') && document.querySelector('input[name="photo_id"]')
                      .value == '')
        ) {
          alert(
            'please make sure you have uploaded both sides of your card and an id photo.',
          );
          event.preventDefault();
        } else {
          document.getElementById('registration').submit();
        }
      } else if (this.personalCardType == 'cert') {
        if (
          (this.passCert.indexOf('certificate-web') !== -1
                        && document.getElementsByName('pass_certificate')[0].files
                          .length === 0)
                    || (document.querySelector('input[name="photo_id"]') && document.querySelector('input[name="photo_id"]')
                      .value == '')
        ) {
          alert(
            'please make sure you have uploaded a copy of your certificate and an id photo.',
          );
          event.preventDefault();
        } else {
          document.getElementById('registration').submit();
        }
      } else if (this.personalCardType === 'citb' && this.citbCardType !== '') {
        alert('CITB is no longer valid, please contact helpdesk to resolve this issue');
      } else if (this.personalCardType === 'gosmart' && this.cardScheme !== '') {
        document.getElementById('registration').submit();
      } else {
        alert('Please choose a card type from the selection.');
      }
    },
    updateQualifications(event) {
      if (this.personalCardType === 'citb') {
        alert('CITB is no longer valid, please contact helpdesk to resolve this issue');
      } else if (this.personalCardType === 'gosmart' && this.citbUpdated) {
        if (this.cardScheme !== '' && this.cardSchemeId !== '') {
          axios
            .get(
              `/citb-card/assign?scheme=${this.cardScheme}&card_number=${this.cardNumber}&last_name=${
                  this.lastName
              }`,
            )
            .then((response) => {
              document.getElementById('update-details').submit();
            });
        } else {
          alert('Please choose a card type from the selection.');
        }
      } else {
        if (this.personalCardType === 'noncscs' || this.personalCardType === 'temp') {
          if (
            ((this.cardFront.indexOf('card-front-web') !== -1
                            || this.cardBack.indexOf('card-back-web') !== -1)
                            && (document.getElementsByName('card_front')[0].files
                              .length === 0
                                || document.getElementsByName('card_back')[0].files
                                  .length === 0))
                        || (document.querySelector('input[name="photo_id"]') && document.querySelector('input[name="photo_id"]')
                          .value == '')
          ) {
            alert(
              'please make sure you have uploaded both sides of your card and an id photo.',
            );

            event.preventDefault();
            return;
          }
        }
        if (this.personalCardType === 'cert') {
          if (
            document.querySelector(
              'input[name="photo_id"]',
            )
                        && document.querySelector(
                          'input[name="photo_id"]',
                        ).value == ''
          ) {
            alert(
              'please make sure you have uploaded a suitable id photo.',
            );
            event.preventDefault();
            return;
          }
        }

        if (this.personalCardType !== 'citb' && this.personalCardType !== 'gosmart') {
          axios
            .get(
              `/citb-card/remove?cardType=${
                  this.personalCardType
              }`,
            )
            .then((response) => {
              document.getElementById('update-details').submit();
            });
        } else {
          document.getElementById('update-details').submit();
        }
      }
    },
    changeCard() {
      this.personalCardType = 'noncscs';
    },
    selectCard(goSmartCard) {
      if (!goSmartCard) return;
      if (goSmartCard.manual === true) {
        if (window.confirm('This card scheme requires you to upload additional information. Are you sure this is your card scheme?')) {
          axios
            .get(
              '/citb-card/remove?cardType=noncscs',
            )
            .then((response) => {
              window.location.reload();
            });
        } else {
          this.chooseVerifiedCardScheme();
        }
      } else {
        this.cardSchemeId = goSmartCard.id;
        this.cardScheme = goSmartCard.abbreviation;
        this.cardSchemeFull = goSmartCard.name;
        this.cardSchemeSrc = goSmartCard.icon_src;
      }
    },
    changeSelect(event) {
      if (event.target.value === 32 || event.target.value === '32') {
        this.chooseVerifiedCardScheme();
      } else {
        this.chooseNonCscsCardScheme();
      }
    },
    chooseVerifiedCardScheme() {
      this.changeToCscs = true;
      this.resetCardSchemeInfo();
    },
    chooseNonCscsCardScheme() {
      this.changeToCscs = false;
      this.resetCardSchemeInfo();
    },
    resetCardSchemeInfo() {
      this.cardSchemeId = '';
      this.cardScheme = '';
      this.cardSchemeFull = '';
      this.cardSchemeSrc = '';
    },
    isMembershipChecked(event) {
      this.$refs.verifyUser.checkedMembership = event.target.checked;
    },
    videoOptions(id, autoplay) {
      let elAutoPlay = '';
      let videoAutoPlay = '';
      if (autoplay) {
        elAutoPlay = 'autoplay; ';
        videoAutoPlay = '?autoplay=1';
      }
      return {
        captions: false,
        closeText: 'X',
        additionalHtml: `<iframe class="video" width="1000px" height="500px" src="https://player.vimeo.com/video/${id}${videoAutoPlay}" frameborder="0" allow="${elAutoPlay}fullscreen;"></iframe>`,
      };
    },
  },
});

// Independant view models
window.addEventListener('DOMContentLoaded', () => {
  (() => new Vue({
    el: '#browser-banner',
    components: {
      uBrowserBanner,
      //   uErrorBanner
    },
  }))();
  const videoVm = '[data-vm="videos"]';
  if (document.querySelector(videoVm)) {
    (() => new Vue({
      el: videoVm,
      components: { ProgressDots, ProgressFraction },
      data() {
        return {
          skipBtn: '#lockoutButton',
        };
      },
      mounted() {
        this.lock();
        const videoEl = document.querySelector('.video');
        const player = new Player(videoEl);

        player.on('ended', this.unlock);
      },
      methods: {
        lock() {
          // Prevent user from skipping video
          document
            .querySelector(this.skipBtn)
            .classList.add('hidden');
        },
        unlock() {
          document
            .querySelector(this.skipBtn)
            .classList.remove('hidden');
        },
      },
    }))();
  }
});


/**
 * Start Sentry
 */



const sentryVars = window?.sentry;

if (sentryVars?.environment && sentryVars?.dsn && sentryVars.environment !== 'dev') {
    Sentry.init({
        // app,
        dsn: sentryVars.dsn,
        environment: sentryVars.environment,
        release: sentryVars?.release,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],

        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0.2,
        replaysOnErrorSampleRate: 1.0,
        profilesSampleRate: 0.2,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: ['localhost', /^\//, /^https:\/\/.*mipreenrolment\..*/, /^\//, /^https:\/\/.*mipre-enrolment\..*/],
    });
    if (sentryVars?.user) {
        Sentry.setUser(sentryVars.user);
    }
}
/**
 * End Sentry
 */

window.App = app;
